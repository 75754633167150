import React from "react";
import PatternLayout from "../../components/layouts/pattern-layout";
import ViewportLock from "../../../packages/ViewportLock/src";
import Div from "../../../packages/Div/src";
import Flex from "../../../packages/Flex/src";
import Button from "../../../packages/Button/src";
import Container from "../../../packages/Container/src";
import { Heading, P } from "../../../packages/Typography/src";

var ImmersiveStoryCoverPattern = function ImmersiveStoryCoverPattern() {
  return React.createElement(PatternLayout, {
    minimal: true,
    navColor: "white",
    logoOutlined: true
  }, React.createElement(ViewportLock, {
    bg: "grey.900",
    color: "white"
  }, React.createElement(Flex, {
    justifyContent: "center",
    alignItems: "center",
    height: "100%"
  }, React.createElement(Div, {
    textAlign: "center"
  }, React.createElement(Container, {
    size: "xs"
  }, React.createElement(Heading, {
    mt: "0"
  }, "Story heading"), React.createElement(P, {
    size: "lg"
  }, "Subtitle for this story"), React.createElement(Button, {
    raised: true,
    variant: "primary",
    size: "lg",
    borderRadius: "pill"
  }, "Start!"))))));
};

export default ImmersiveStoryCoverPattern;